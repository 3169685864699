import React, { ReactNode } from 'react';
import { EntryMetaInfoWithQueriedResources } from '../../types/app.types';
import joinClassName from '../../utils/className.utils';
import { isNumber } from '../../utils/typeChecks.utils';
import AppScreenshotSlider from '../AppScreenshotSlider/AppScreenshotSlider';
import GatsbyImageAdaptor from '../GatsbyImageAdaptor/GatsbyImageAdaptor';
import './SimpleEntryGallery.scss';

type SimpleEntryGalleryProps = {
  className?: string,
  entry: EntryMetaInfoWithQueriedResources,
  range?: [number, number],
  doNotRenderContainer?: boolean,
  autoAddMobileSlides?: boolean,
  mobileSlidesPosition?: number,
}

const SimpleEntryGallery: React.FC<SimpleEntryGalleryProps> = props => {
  const mobileScreenshots = props.entry.mobileScreenshots;
  const slides: ReactNode[] = (props.entry.gallery ?? []).slice(props.range?.[0] ?? 0, props.range?.[1] ?? (props.entry.gallery?.length ?? 0)).map((entry, i) => React.createElement(
    props.doNotRenderContainer ? 'div' : 'li',
    { 
      key: i,
      className: "SimpleEntryGalleryItem",
      children: <GatsbyImageAdaptor
        image={entry}
      />
    }
  ));
  if (mobileScreenshots && (props.autoAddMobileSlides || isNumber(props.mobileSlidesPosition))) slides.splice(props.mobileSlidesPosition ?? 1, 0, <AppScreenshotSlider slides={mobileScreenshots} key="mobileScreenshots" />)
  if (props.doNotRenderContainer) return <>{slides}</>
  return <ul className={joinClassName('SimpleEntryGallery', props.className)}>
    { slides }
  </ul>
}

export default SimpleEntryGallery;