import { graphql, useStaticQuery } from "gatsby";
import { Category } from "../../constants/categories.constants";
import { Sector } from "../../constants/sectors.constants";
import { ImageWithMetaInfo, ProjectMetaInfo, ProjectMetaInfoWithQueriedResources } from "../../types/app.types";
import { CelticWoodlandYogaFestivalImagesQuery } from "../../types/generated";

export const ProjectMetaInfoCelticWoodlandYogaFestival: ProjectMetaInfo = {
  name: "celtic-woodland-yoga-festival",
  displayName: "Celtic Woodland Yoga Festival",
  abbreviation: "CWYF",
  subtitle: "Website Design & Build Project",
  description: "The Celtic Woodland Yoga festival is dedicated to broad-mindedness, diversity and conscious exploration, and aims to grow this yoga into the future as a living, breathing spiritual art form. The platform was first developed to host its first virtual yoga festival in 2020.",
  categories: [
    Category.websites,
  ],
  sectors: [
    Sector.healthAndFitness,
    Sector.entertainmentAndEvents,
  ],
  url: "https://celticwoodlandyogafestival.com/",
  hasPage: true,
  keywords: [
    'Celtic Woodland Yoga Festival',
    'web design',
    'web development',
    'Yoga',
    'Yoga Festival',
    'live event streaming',
    'WordPress',
    'events',
  ],
}
export const useCelticWoodlandYogaFestivalInfo = () => {
  const CelticWoodlandYogaFestivalImages: CelticWoodlandYogaFestivalImagesQuery = useStaticQuery(graphql`
    query CelticWoodlandYogaFestivalImages {
      featuredImage: file(relativePath: {eq: "images/celtic-woodland-yoga-festival/celtic-woodland-yoga-festival-library-class.jpg"}) {
        childImageSharp {
          gatsbyImageData(width: 1280, height: 720)
        }
      }
      gallery: allFile(
        sort: {fields: name},
        filter: {
          extension: {regex: "/(jpg)|(jpeg)|(png)/"},
          relativeDirectory: {eq: "images/celtic-woodland-yoga-festival/gallery"}
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 2560, height: 1440)
            }
          }
        }
      }
    }
  `)
  const imageMetaData: Partial<ImageWithMetaInfo>[] = [
    {
      title: 'Celtic Woodland Yoga Festival Homepage Screenshot',
      alt: 'Celtic Woodland Yoga Festival Homepage Screenshot, desktop version',
    },
  ]
  const info: ProjectMetaInfoWithQueriedResources = {
    ...ProjectMetaInfoCelticWoodlandYogaFestival,
    featuredImage: {
      image: CelticWoodlandYogaFestivalImages.featuredImage!.childImageSharp,
      title: 'Celtic Woodland Yoga Festival',
      alt: 'Yogis having a group class in Townley Hall during Celtic Woodland Yoga Festival 2019',
    },
    gallery: CelticWoodlandYogaFestivalImages.gallery.edges.map((edge, i) => ({
      image: edge.node.childImageSharp?.gatsbyImageData,
      ...imageMetaData[i]
    }))
  }
  return info;
}