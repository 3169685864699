import React from 'react';
import NativeVideoWrapper from '../../components/NativeVideoWrapper/NativeVideoWrapper';
import ProjectPage from '../../components/ProjectPage/ProjectPage';
import SimpleEntryGallery from '../../components/SimpleEntryGallery/SimpleEntryGallery';
import Spacer from '../../components/Spacer/Spacer';
import videoPoster from '../../content/images/celtic-woodland-yoga-festival/celtic-woodland-yoga-festival-homepage-video-loop-poster.jpg';
// import './ProjectPageCelticWoodlandYogaFestival.scss';
import videoWebmSD from '../../content/images/celtic-woodland-yoga-festival/celtic-woodland-yoga-festival-homepage-video-loop-sd.webm';
import videoMp4 from '../../content/images/celtic-woodland-yoga-festival/celtic-woodland-yoga-festival-homepage-video-loop.mp4';
import { useCelticWoodlandYogaFestivalInfo } from '../../content/projects/celtic-woodland-yoga-festival.project';
import { PageComponent } from '../../types/gatsby.types';

type ProjectPageCelticWoodlandYogaFestivalProps = {}

const ProjectPageCelticWoodlandYogaFestival: PageComponent<ProjectPageCelticWoodlandYogaFestivalProps> = props => {
  const metaInfo = useCelticWoodlandYogaFestivalInfo();
  return <ProjectPage
    {...props} // always include
    project={metaInfo}
  >
    <NativeVideoWrapper
      id="CelticWoodlandYogaFestivalHomepageVideoLoop"
      className="CelticWoodlandYogaFestivalHomepageVideoLoop"
      src={[videoWebmSD, videoMp4]}
      poster={videoPoster}
      width={1280}
      height={720}
      onlyLoadWhenVisible
      rounded
    />
    <Spacer size="pageMargin" />
    <SimpleEntryGallery entry={metaInfo} autoAddMobileSlides range={[1, Infinity]}/>
  </ProjectPage>
}

export default ProjectPageCelticWoodlandYogaFestival;